/* footer */
footer{
    position: relative;
    .logo{
        width: 260px;
        height: 70px;
        margin: 0 auto;
        position: absolute;
        top: -35px;
        right: 0;
        left: 0;
        padding: 0 17px;
        z-index: 1;
        >img{
            @include contain;
        }
    }
    .footer-top{
        background-color: #fafafa;
        padding: 65px 0;
        font-size: 16px;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            height: 1px;
            border-top: 1px solid #cbcbcb;
            left: 0;
            width: 42vw;
            z-index: 0;
        }
        &:after{
            content: '';
            position: absolute;
            top: 0;
            height: 1px;
            border-top: 1px solid #cbcbcb;
            right: 0;
            width: 42vw;
        }
        .container{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        p{
            margin-bottom: 15px;
            color: #353535;
        }
        a{
            display: block;
            color: #353535;
            font-size: 16px;
        }
        h3{
            color: #727272;
            font-size: 21px;
            margin-bottom: 15px;
        }
        ul{
            @include ul-default;
            li{
                margin-bottom: 10px;
                a{
                    display: block;
                    color: #353535;
                    font-size: 16px;
                    &:hover{
                        color: #0bb4c4;
                    }
                }
                &.active{
                    a{
                        color: #0bb4c4;
                    }
                }
            }
        }
        .footer-col{
            .col--phone{
                margin-bottom: 10px;
            }
            .col--mobile{
                a{
                    margin-bottom: 10px;
                }
            }
        }
        @media screen and (max-width: 768px){
            padding: 45px 0;
            .footer-col{
                margin: 10px;
                &.nav{
                    display: none;
                }
                &.services{
                    display: none;
                }
            }
            h3{
                margin-bottom: 10px;
            }
            a{
                margin-bottom: 5px;
            }
            ul{
                li{
                    margin-bottom: 5px;
                }
            }
        } 
        @media screen and (max-width: 568px){
            padding: 45px 0 25px 0;
        }
    }
    .footer-bottom{
        padding: 23px 0;
        background-color: #0bb4c4;
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                font-size: 16px;
                color: #fff;
            }
            .artmedia{
                position: relative;
                padding-right: 130px;
                color: #fff;
                font-size: 16px;
                &:hover{
                    color: #faa643;
                }
                >img{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }
            }
        }
        @media screen and (max-width: 768px){
            .container{
                display: block;
                text-align: center;
                p{
                    margin-bottom: 10px;
                    font-size: 14px;
                }
                .artmedia{
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 568px){
        .logo{
            width: 200px;
        }
    }
}
/* footer */