/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
    font-family: 'PT Sans Caption';
    src: url('../fonts/PTSans-CaptionBold.woff2') format('woff2'),
        url('../fonts/PTSans-CaptionBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PT SansI';
    src: url('../fonts/PTSans-Italic.woff2') format('woff2'),
        url('../fonts/PTSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PT Sans Narrow';
    src: url('../fonts/PTSans-NarrowBold.woff2') format('woff2'),
        url('../fonts/PTSans-NarrowBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PT SansR';
    src: url('../fonts/PTSans-Regular.woff2') format('woff2'),
        url('../fonts/PTSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../fonts/PTSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/PTSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'PT Sans Narrow';
    src: url('../fonts/PTSans-Narrow.woff2') format('woff2'),
        url('../fonts/PTSans-Narrow.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans Caption';
    src: url('../fonts/PTSans-Caption.woff2') format('woff2'),
        url('../fonts/PTSans-Caption.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT SansB';
    src: url('../fonts/PTSans-Bold.woff2') format('woff2'),
        url('../fonts/PTSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}


$regular: 'PT SansR';
$bold: 'PT SansB';
$italic: 'PT SansI';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}
@mixin contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
}
@mixin cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	outline: none;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	padding-top: 110px;
	font-size: 18px;
    @media screen and (max-width: 1024px) {
		padding-top: 80px;
	}
    @media screen and (max-width: 768px) {
		display: block;
		font-size: 16px;
    }
}

h1 {
	font-family: $regular;
	font-size: 40px;
	text-transform: uppercase;
	color: #0bb3c3;
	line-height: 1;
	margin: 0 0 40px;
    a {
        font-size: 42px;
        color: #252525;
        &:hover {
            color: #0bb3c3;
        }
    }
	@media screen and (max-width: 1200px) {
		font-size: 32px;
        a {
            font-size: 32px;
        }
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 30px;
        a {
            font-size: 28px
        }
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 20px;
        a {
            font-size: 24px;
        }
    }
}
h2 {
	font-family: $bold;
	font-size: 36px;
	color: #0bb3c3;
	margin: 0 0 40px;
	text-transform: uppercase;
	text-align: center;
	a {
        font-size: 36px;
		color: #0bb3c3;
        &:hover {
            color: #0bb3c3;
        }
	}
	@media screen and (max-width: 1200px) {
		font-size: 30px;
		a {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
        margin-bottom: 30px;
		a {
			font-size: 24px;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 20px;
    }
}
h3 {
	font-family: $bold;
	font-size: 24px;
	color: #252525;
	@media screen and (max-width: 1024px) {
		font-size: 22px;
	}
    @media screen and (max-width: 20px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $bold;
	font-size: 22px;
	color: #252525;
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #252525;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #252525;
}
a {
    color: #252525;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #252525;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #252525;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        width: 100%;
		font-family: $regular;
		font-size: 16px;
		padding: 10px 15px;
        margin-bottom: 15px;
		box-shadow: none;
		border: none;
		background-color: #d1fbff;
		border-radius: 50px;
		&::placeholder{
			color: #353535;
		}
	}
	textarea{
		border-radius: 10px;
		resize: none;
	}
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.main {
	.container {
		padding-top: 100px;
		padding-bottom: 100px;
		@media screen and (max-width: 1440px) {
			padding-top: 80px;
			padding-bottom: 80px;
		}
		@media screen and (max-width: 1200px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 1024px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
}

.container {
    width: 1170px;
    padding: 0 15px;
	margin: 0 auto;
	@media screen and (max-width: 1169px){
		width: 100%;
	}
}

.title-block{
	padding: 0 17px;
	position: relative;
	&:before{
		content: '';
		position: absolute;
		left: 0;
		height: 1px;
		border-top: 1px solid #d7d7d7;
		width: 24vw;
		top: 50%;
		transform: translateY(-50%);
	}
	&:after{
		content: '';
		position: absolute;
		right: 0;
		height: 1px;
		border-top: 1px solid #d7d7d7;
		width: 24vw;
		top: 50%;
		transform: translateY(-50%);
	}
}
.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: #0bb3c3;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: table;
	width: auto!important;
	font-family: $bold;
	font-size: 18px;
	color: #fff;
    text-align: center;
	cursor: pointer;
	border-radius: 0px;
	background-color: #0bb3c3;
	padding: 13px 40px;
	border-radius: 40px !important;
	line-height: 1;
	border: none;
	transition: 300ms;
	&:hover, &:active{
		background-color: #ffb10b;
		color: #fff!important;
	}
	&:focus{
		color: #fff;
	}
	@media screen and (max-width: 768px){
		padding: 8px 30px;
		font-size: 16px;
	}	
}

.btn-2{
	display: table !important;
	background: none;
	font-family: $bold;
	text-align: center;
	cursor: pointer;
	color: #fff;
	position: relative;
	border: none;
	font-size: 16px;
	padding: 0 30px;
	margin: 0 auto;
	&:after{
		content: '';
		position: absolute;
		right: 0;
		top: 5px;
		background: url(../img/arrow.png) no-repeat center center;
		background-size: contain;
		width: 22px;
		height: 13px;
	}
}
.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #0bb2c2;
			left: 0;
			top: 0px;
		}
	}
}

.col--address,
.col--phone,
.col--email,
.col--mobile,
.col--time {
	position: relative;
	padding: 0 15px 0 20px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 16px;
		color: #0bb3c3;
		position: absolute;
		top: 3px;
		left: 0;
	}
}
.col--address:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 16px;
	top: 2px;
}
.col--time:after{
	background: url(../img/schedule.png) no-repeat center center;
	background-size: contain;
	width: 15px;
	height: 15px;
}
.col--mobile:after{
	content: "\f10b";
}
.col--phone a,
.col--mobile a,
.col--phone,
.col--mobile{
	pointer-events: none;
	.more-btn{
		pointer-events: auto;
	}
	@media screen and (max-width: 1024px){
		pointer-events: auto;
	}
}
.col--email:hover{
	color: #0bb2c2;
}
.more-btn{
	position: absolute;
	top: 3px;
	right: 0;
	color: #fff;
	font-family: 'FontAwesome';
	&:after{
		content: "\f107";
	}
	&.open{
		transform: rotate(180deg);
	}
}

/* header */
header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 9;
	box-shadow: 0 0 10px rgba(0,0,0,0.5);
	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.top{
		padding: 7px 0;
		background-color: #0bb2c2;
		color: #fff;
		font-size: 16px;
		.hts{
			display: flex;
			.col--time{
				cursor: pointer;
				ul{
					@include ul-default;
					display: none;
					position: absolute;
					top: 25px;
					left: 0px;
					right: 0;
					background-color: #0bb2c2;
					box-shadow: 0 0 5px rgba(0,0,0,0.5);
					padding: 10px;
					width: 160px;
					display: none;
					z-index: 1;
				}
			}
			.col--phone{
				margin-left: 20px;
				position: relative;
				&:after{
					color: #fff;
				}
				a{
					color: #fff;
					white-space: nowrap;
					font-size: 16px;
				}
				ul{
					@include ul-default;
					position: absolute;
					top: 25px;
					left: 25px;
					right: 0;
					background-color: #0bb2c2;
					box-shadow: 0 0 5px rgba(0,0,0,0.5);
					padding: 10px;
					width: 160px;
					display: none;
					z-index: 1;
					li{
						a{
							color: #fff;
							font-size: 16px;
						}
					}
				}
			}
		}
	}
	.bottom{
		background-color: #fff;
		.container{
			display: flex;
		}
		.logo{
			width: 190px;
			height: 50px;
			>img{
				@include contain;
			}
		}
		.nav{
			@include ul-default;
			display: flex;
			li{
				a{
					color: #353535;
					font-size: 18px;
					padding: 30px 10px;
					display: block;
					border-bottom: 4px solid transparent;
					&:hover{
						border-bottom: 4px solid #0bb3c3;
					}
				}
				&.active{
					a{
						border-bottom: 4px solid #0bb3c3;
					}
				}
			}
		}
		.write--btn{
			font-family: $regular;
			background-color: #ffb10b;
			padding: 13px 30px;
			font-size: 16px;
			&:hover{
				background-color: #0bb2c2;
			}
		}
		.langs{
			position: relative;
			padding: 0 13px 0 0;
			span{
				cursor: pointer;
				font-size: 18px;
			}
			ul{
				@include ul-default;
				position: absolute;
				top: 25px;
				right: 3px;
				left: -3px;
				background-color: #0bb2c2;
				padding: 5px;
				box-shadow: 0 0 5px rgba(0,0,0,0.5);
				display: none;
				li{
					a{
						color: #fff;
					}
				}
			}
			.more-btn{
				color: #252525;
				font-size: 14px;
				top: 6px;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.bottom{
			.nav{
				li{
					a{
						font-size: 16px;
						padding: 30px 5px;
					}
				}
			}
			.write--btn{
				padding: 13px 15px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		background-color: #fff;
		height: 80px;
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #0bb2c2;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
		.phone--btn{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 10px;
			color: #0bb3c3;
			font-size: 20px;
			font-family: 'FontAwesome';
			&:after{
				content: '\f095';
			}
			&.open{
				&:after{
					content: '\f00d';
				}
			}
		}
		.time--btn{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 40px;
			color: #0bb3c3;
			font-size: 20px;
			font-family: 'FontAwesome';
			&:after{
				content: "\f017";
			}
			&.open{
				&:after{
					content: '\f00d';
				}
			}
		}
		.write-btn{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 70px;
			color: #0bb3c3;
			font-size: 20px;
			font-family: 'FontAwesome';
			&:after{
				content: "\f044";
			}
			&.open{
				&:after{
					content: '\f00d';
				}
			}
		}
		.top{
			background: none;
			.address{
				display: none;
			}
			.hts{
				.col--time{
					display: none;
					position: fixed;
					top: 80px;
					right: 0;
					left: 0;
					background-color: #0bb3c3;
					padding: 10px;
					text-align: center;
					p{
						text-align: center;
					}
					&:after{
						display: none;
					}
					.more-btn{
						display: none;
					}
					ul{
						display: block;
						position: relative;
						padding: 0;
						top: 0;
						box-shadow: none;
						text-align: center;
						margin: 0 auto;
						p{
							text-align: center;
						}
					}
				}
				.col--phone{
					display: none;
					position: fixed;
					top: 80px;
					right: 0;
					left: 0;
					background-color: #0bb3c3;
					padding: 10px;
					text-align: center;
					margin: 0;
					&:after{
						display: none;
					}
					.more-btn{
						display: none;
					}
					ul{
						position: relative;
						top: 0;
						left: 0;
						box-shadow: none;
						padding: 0;
						display: block !important;
						margin: 0 auto;
					}
				}
			}
		}
		.bottom{
			.logo{
				width: 120px;
				height: 70px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				left: 0;
				margin: 0 auto;
			}
			.nav{
				display: none;
				position: fixed;
				top: 80px;
				right: 0;
				left: 0;
				background-color: #fff;
				max-height: calc(100% - 80px);
				border-top: 1px solid #0bb2c2;
				border-bottom: 1px solid #0bb3c3;
				padding: 15px;
				>li{
					>a{
						padding: 3px;
						display: table;
					}
				}
			}
			.write--btn{
				display: none;
			}
			.langs{
				position: absolute;
				left: 48px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}
/* header */